import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// plug-ins
import 'moment/locale/ru';

// screens
import Start				from './Screens/Start';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />

			{/*
			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
			*/}

		</Switch>
	</BrowserRouter>
);

export default App;