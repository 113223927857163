import React, {useEffect,useState} from 'react';

// styles
import './styles.css';

// start
const StartScreen = () => {
	const [loading, setLoading] = useState(true);
	useEffect(async () => {
		const dataGet = async () => {
		};
		await dataGet();
		setLoading(false);
	}, []);

	return loading ? null : <div className="main">
            <h1>client.pastilapp.ru</h1>
        </div>
	;
};

export default StartScreen;